import classNames from "classnames";
import React, { ReactNode } from "react"

type ProjectHeroProps = {
  background?: string;
  text?: {
    title?: string;
    text?: string;
  };
  body?: ReactNode[];
  title?: string;
  subtitle?: string;
  type?: "paragraphs" | "inline" | "stacked";
  content?: {
    title?: string;
    body?: ReactNode[];
  }[]
}

function ProjectTitle({ background, body, content, subtitle, text, title, type = "inline" }: ProjectHeroProps) {

  let textColor = 'black';
  if (text && text.text) {
    textColor = text.text;
  }

  let titleColor = 'black';
    if (text && text.title) {
      titleColor = text.title;
    }

    return (
      <section data-background={background || "ltOrange"} className={'py-28 md:py-48 px-5 md:px-[2.25vw] lg:px-[3vw]'}>
          {content && content.length && content.map((block, index) => {
            return (
              <div key={`project-item--${block.title?.toLowerCase().replace(/ /g,'-')}__${index}`} className="flex justify-end mb-12 last:mb-0">
                <div className={`md:w-8/12`}>
                  <h3 className={classNames('font-light font-mono mb-4 text-[10px] sm:text-[1.5625vw] md:text-[1.30208333333vw] lg:text-[0.9765625vw] xl:text-[0.78125vw]', {
                    'text-white/80': background === 'black' && titleColor === 'black',
                    'text-primary-orange': titleColor === 'orange',
                    'text-primary-purple': titleColor === 'purple',
                    'text-white': titleColor === 'white',
                    'text-astonMartin-primary': titleColor === 'astonMartin',
                    'text-tgiFriday-primary': titleColor === 'tgiFridays',
                    'text-black': background !== 'black' && titleColor === 'black',
                  })}>{block.title}</h3>
                  {block.body && block.body.length && block.body.map((item, i) => 
                    <p
                      key={`project-item-title--${block.title?.toLowerCase().replace(/ /g,'-')}__${i}`}
                      className={classNames(`font-thin font-sans leading-relaxed mb-6 last:mb-0 text-[14px] sm:text-[2.1875vw] md:text-[1.82291666667vw] lg:text-[1.3671875vw] xl:text-[1.09375vw] w-full`, {
                        'text-white/80': background === 'black' && textColor === 'black',
                        'text-primary-orange': textColor === 'orange',
                        'text-white': textColor === 'white',
                        'text-primary-purple': textColor === 'purple',
                        'text-black': background !== 'black' && textColor === 'black',
                      })} dangerouslySetInnerHTML={{ __html: item}} />
                  )}
                </div>
              </div>
            )
          })}
      </section>
    )

  
}

export default ProjectTitle
